<template>
  <b-card
    no-body
    class="mb-1"
  >
    <b-card-header class="py-1">
      <h4 class="card-title font-weight-bolder">
        {{ $t('filters') }}
      </h4>
      <div class="d-flex-center justify-content-end gap-2">
        <!-- ANCHOR Button Reload -->
        <b-button
          variant="primary"
          class="mr-1"
          @click="onUpdateFilters"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="RefreshCcwIcon"
              size="16"
            />
            {{ $t('refresh') }}
          </span>
        </b-button>
        <!-- SEARCH BUTTON -->
        <b-button
          :variant="!emptyFilters ? 'warning' : ''"
          class="min-width-120"
          :class="{ 'cursor-not-allowed': emptyFilters }"
          :disabled="emptyFilters"
          @click="onUpdateFilters"
        >
          <span class="text-nowrap d-flex-center gap-1">
            <feather-icon
              class="cursor-pointer"
              icon="SearchIcon"
              size="16"
            />
            {{ $t('search') }}
          </span>
        </b-button>

        <!-- CLEAR BUTTON -->
        <b-button
          :variant="!emptyFilters ? 'danger' : ''"
          :class="{ 'cursor-not-allowed': emptyFilters, 'min-width-120': true }"
          :disabled="emptyFilters"
          @click="onClearFilters"
        >
          <span class="text-nowrap d-flex-center gap-1">
            <feather-icon
              class="cursor-pointer"
              icon="XOctagonIcon"
              size="16"
            />
            {{ $t('clear') }}
          </span>
        </b-button>
      </div>
    </b-card-header>

    <b-card-body class="py-0">
      <b-row class="mb-0">
        <!-- START DATE -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <template
              #label
              for="startDate"
            >
              <h5 class="mb-0">
                {{ $t('topup.startDate') }}
              </h5>
            </template>
            <flat-pickr
              id="startDate"
              :value="startDateFilter"
              class="form-control"
              :placeholder="$t('topup.placeholderSelectDate')"
              :config="{
                allowInput: true,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                altInput: true,
                maxDate: maxStartDate,
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
              @input="(val) => $emit('update:startDateFilter', val)"
            />
          </b-form-group>
        </b-col>

        <!-- END DATE -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <template
              #label
              for="endDate"
            >
              <h5 class="mb-0">
                {{ $t('topup.endDate') }}
              </h5>
            </template>
            <flat-pickr
              id="endDate"
              :value="endDateFilter"
              class="form-control"
              :placeholder="$t('topup.placeholderSelectDate')"
              :config="{
                allowInput: true,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                altInput: true,
                minDate: minEndDate,
                maxDate: today,
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
              @input="(val) => $emit('update:endDateFilter', val)"
            />
          </b-form-group>
        </b-col>

        <!-- TOPUP STATUS -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <template
              #label
              for="paymentStatus"
            >
              <h5 class="mb-0">
                {{ $t('topup.paymentStatus') }}
              </h5>
            </template>

            <v-select
              id="paymentStatus"
              :value="paymentStatusFilter"
              clearable
              :placeholder="$t('placeholderSelect')"
              :options="statusTopupOptionsWithAll"
              :reduce="val => val.value"
              @input="(val) => $emit('update:paymentStatusFilter', val)"
            >
              <template #option="data">
                <b-badge
                  :variant="resolveTopupStatusVariant(data.value)"
                  class="text-uppercase"
                  pills
                >
                  {{ $t(data.label) }}
                </b-badge>
              </template>
              <template #selected-option="data">
                <b-badge
                  :variant="resolveTopupStatusVariant(data.value)"
                  class="text-uppercase"
                  pills
                >
                  {{ $t(data.label) }}
                </b-badge>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  computed, watch, toRefs, onMounted, onUnmounted,
} from '@vue/composition-api'
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormGroup,
  BBadge,
} from 'bootstrap-vue'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import { resolveTopupStatusVariant } from '@/constants/selectOptions'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormGroup,
    BBadge,
    vSelect: () => import('vue-select'),
    flatPickr: () => import('vue-flatpickr-component'),
  },
  props: {
    searchKeywords: {
      type: String,
      default: '',
    },
    startDateFilter: {
      type: [String, null],
      default: null,
    },
    endDateFilter: {
      type: [String, null],
      default: null,
    },
    paymentStatusFilter: {
      type: [String, null],
      default: null,
    },
    paymentStatusByRoute: {
      type: String,
      default: '',
    },
    statusTopupOptions: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const {
      startDateFilter, endDateFilter, paymentStatusFilter, searchKeywords,
    } = toRefs(props)
    // const selectedStatus = ref(null)
    // const startDate = ref('')
    // const endDate = ref('')

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    const maxStartDate = computed(() => (endDateFilter.value ? endDateFilter.value : today))
    const minEndDate = computed(() => (startDateFilter.value ? startDateFilter.value : undefined))
    const emptyFilters = computed(() => !(
      paymentStatusFilter.value
      || startDateFilter.value
      || endDateFilter.value
      || searchKeywords.value
    ))

    const onClearFilters = () => {
      emit('clearFilter')
    }

    const onUpdateFilters = () => {
      // const filtersData = {
      //   paymentStatus: selectedStatus.value || undefined,
      //   startDate: startDate.value || undefined,
      //   endDate: endDate.value || undefined,
      // }
      emit('refetchData')
    }

    watch(emptyFilters, val => {
      if (val) emit('refetchData')
    })

    // watch(props, ({ paymentStatusByRoute }, oldValue) => {
    //   if (selectedStatus.value !== paymentStatusByRoute && oldValue?.paymentStatusByRoute !== paymentStatusByRoute) {
    //     console.log({ paymentStatusByRoute })
    //     selectedStatus.value = paymentStatusByRoute
    //   }
    // }, { immediate: true, deep: true })

    const statusTopupOptionsWithAll = computed(() => props.statusTopupOptions.concat({ label: 'All', value: null }))

    let intervalId

    onMounted(() => {
      intervalId = setInterval(onUpdateFilters, 120000) // 2 minutes
    })

    onUnmounted(() => {
      clearInterval(intervalId) // Dừng khi component bị destroy
    })
    return {
      today,
      maxStartDate,
      minEndDate,
      emptyFilters,

      onClearFilters,
      onUpdateFilters,
      Vietnamese,
      statusTopupOptionsWithAll,
      resolveTopupStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
